import { Accordion, Container } from 'components';
import { SEO } from 'components/SEO';
import { orderPanels, technicalPanels } from 'consts';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { FaqNavigation } from 'models';
import React, { useState } from 'react';
import { faqStyles } from 'styles';

const Faq: React.FC = () => {
  const length = orderPanels.length + technicalPanels.length;

  const initalState = Array(length).fill(false);
  const [isOpen, setIsOpen] = useState<boolean[]>(initalState);
  const [navigation, setNavigation] = useState<FaqNavigation>('all');

  return (
    <Container>
      <SEO productName={'FAQ'} />
      <div css={faqStyles.container}>
        <div css={faqStyles.content}>
          <h2 css={faqStyles.title}>
            <FormattedMessage id="faq.title" />
          </h2>
          <div css={faqStyles.navigationWrapper}>
            <button
              css={[
                faqStyles.navigationButton,
                navigation === 'all' && faqStyles.navigationButtonActive,
              ]}
              data-id="all"
              onClick={handleChangeNavigation}
            >
              <FormattedMessage id="faq.all" />
            </button>
            <button
              css={[
                faqStyles.navigationButton,
                navigation === 'orders' && faqStyles.navigationButtonActive,
              ]}
              data-id="orders"
              onClick={handleChangeNavigation}
            >
              <FormattedMessage id="faq.orders" />
            </button>
            <button
              css={[
                faqStyles.navigationButton,
                navigation === 'technical' && faqStyles.navigationButtonActive,
              ]}
              data-id="technical"
              onClick={handleChangeNavigation}
            >
              <FormattedMessage id="faq.tech-questions" />
            </button>
          </div>
          {(navigation === 'all' || navigation === 'orders') && (
            <>
              <p css={faqStyles.subtitle}>
                <FormattedMessage id="faq.orders" />
              </p>
              <div>
                {orderPanels.map((panel) => (
                  <Accordion
                    key={panel.id + panel.title}
                    {...panel}
                    isOpen={isOpen[panel.id]}
                    handleChangeAccordion={handleAccordionChange}
                  />
                ))}
              </div>
            </>
          )}
          {(navigation === 'all' || navigation === 'technical') && (
            <>
              <p css={faqStyles.subtitle}>
                <FormattedMessage id="faq.tech-questions" />
              </p>
              <div>
                {technicalPanels.map((panel) => (
                  <Accordion
                    key={panel.id + panel.title}
                    {...panel}
                    isOpen={isOpen[panel.id]}
                    handleChangeAccordion={handleAccordionChange}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  );

  function handleAccordionChange(id: number) {
    if (isOpen[id]) {
      setIsOpen(initalState);
      return;
    }
    const mutated = [...initalState];
    mutated[id] = true;
    setIsOpen(mutated);
  }

  function handleChangeNavigation(event: React.MouseEvent<HTMLButtonElement>) {
    const { id } = event.currentTarget.dataset;

    if (!id) return;
    setNavigation(id as FaqNavigation);
  }
};

export default Faq;
